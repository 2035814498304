<template>
  <v-app id="home">
    <AppBar/>
    <v-main>
      <router-view/>
    </v-main>
    <Footer/>
  </v-app>
  
</template>

<script>
import AppBar from './AppBar.vue'
import Footer from './Footer.vue'

export default {
  components: {
    AppBar,
    Footer,
  }
}
</script>
