<template>
  <section id="footer">
    <v-row align="center" justify="center">
      <v-col cols="12" xl="8" lg="10">        
        <LetsTalk/>
        <FooterNav/>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import LetsTalk from './LetsTalk.vue'
import FooterNav from './FooterNav.vue'

export default {
  components: {
    LetsTalk,
    FooterNav
  }
}
</script>