<template>
  <section id="footer-nav" class="mt-10">
    <v-footer height="100" color="#fff" padless class="d-flex justify-space-around">
      <router-link to="/">
        <v-img
          max-height="150"
          max-width="150"
          :lazy-src="`${$assetStorage}/logo.png`"
          :src="`${$assetStorage}/logo.png`"
        >
        </v-img>
      </router-link>
      <div class="poppins" style="font-size: 13px">
        © Copyright 2021. All rights reserved.
      </div>
      <v-btn text class="poppins text-capitalize black--text" v-for="(item, i) in items" :key="i" style="font-size: 13px; letter-spacing: 0px">
        {{item.title}}
      </v-btn>
    </v-footer>
  </section>
</template>

<script>
export default {
  data: () => ({
    items: [
      {title: 'About Us', route: ''},
      {title: 'Products', route: ''},
      {title: 'Shop', route: ''},
      {title: 'Term of Use', route: ''},
      {title: 'Data Privacy', route: ''},
    ]
  })
}
</script>