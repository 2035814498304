<template>
  <section id="app-bar">
    <v-app-bar class="d-flex justify-center align-center" app height="50" elevate-on-scroll :color="scrolled ? 'white' : 'rgba(57, 102, 216, 0.15)'">
      <div class="poppins primary--text f14">
        <b class="">Covid 19 Response: No one should be left behind.</b>
        Check out our different ways of helping schools and institutions during the pandemic
        <v-btn icon small>
          <v-icon color="primary">mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <v-app-bar class="landing-appbar" :height="$vuetify.breakpoint.xlOnly? 150: 120" color="white" flat style="margin-top: 50px">
      <v-row align="center" justify="center">
        <v-col cols="12" xl="9" lg="11" class="d-flex align-center">
          <v-toolbar flat :height="$vuetify.breakpoint.xlOnly? 150: 120">
            <!-- <v-toolbar-title> -->
              <v-img
              :lazy-src="`${$assetStorage}/pci-logo.png`"
              :src="`${$assetStorage}/pci-logo.png`"
              max-height="150"
              max-width="150"
              />
            <!-- </v-toolbar-title> -->
            <v-spacer/>
            <v-btn 
              text 
              exact 
              plain
              class="list poppins mx-5" 
              v-for="(item, i) in items" 
              :key="i" 
              :to="item.route"
              :class="item.title == $route.name ? 'active' : ''"
            >
              {{item.title}}
            </v-btn>
            <v-btn  elevation="0" large color="success" class="mx-5 list-btn">
              Book a demo
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-app-bar>
  </section>
</template>

<script>
export default {
  data:() => ({
    items: [
      {
        title: 'Home',
        route: { name: 'Home' },
        subitems: []
      },
      {
        title: 'About Us',
        route: { name: 'About Us' },
        subitems: []
      },
      {
        title: 'Products',
        route: { name: 'Home1' },
        subitems: []
      },
      {
        title: 'Blogs',
        route: { name: 'Home1' },
        subitems: []
      },
      {
        title: 'Contact Us',
        route: { name: 'Contact Us' },
        subitems: []
      },
    ],
    scrolled: false
  }),
   methods: {
    scroll() {
      window.onscroll = () => {
        var page_offset = window.pageYOffset;
        if (page_offset < 50) {
          this.scrolled = false;
        } else {
          this.scrolled = true;
        }
      };
    }
  },
  mounted() {
    this.scroll();
  }
}
</script>