<template>
  <section id="lets-talk" class="">
    <v-sheet rounded="xl" color="primary" 
      :class="
        $route.name === 'About Us' ? 'd-none' 
        : $route.name === 'Contact Us' ? 'd-none'
        : 'mb-16 px-16 d-flex justify-space-around'
      "
    >
      
      <div class="align-self-end"><v-img :src="`${$assetStorage}/cat.png`"/></div> 
      <div class="text-center my-16">
        <div class="section-title white--text" style="font-size: 30px">
          Want to see what Educat can do for you?
        </div>
        <div class="noto my-2 white--text col-lg-10 mx-auto" style="font-size: 16px; line-height: 183.85%">
          Learn more by booking a demo today and discover why Educat is the only platform you'll ever need for your learning and training needs.
        </div>
        <v-btn x-large color="#fff" class="primary--text my-2 font-weight-bold poppins" width="250" flat style="border-radius: 0px">
          LET'S TALK!
        </v-btn>
      </div>
      <div class="align-self-end"><v-img :src="`${$assetStorage}/paws.png`"/></div>
    </v-sheet>
    <div class="d-flex justify-center">
      <div class="d-flex align-center">
        <div class="mx-5" v-for="(item, n) in socials" :key="n">
          <v-btn icon >
            <v-img max-height="24" max-width="24"  :src="`${$assetStorage}/social/${item}.png`"/>
          </v-btn>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    socials: ['facebook','instagram','pci_logo', 'twitter']
  })
}
</script>
